import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from "../components/logo"

const HeaderWrapper = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 12px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    padding: 12px 0;
  }
`

const Header = styled.header`
  align-items: center;
  color: #0f0f23;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  width: 100%;

  a {
    color: #4579b9;
    text-decoration: none;
  }
`

const StyledLogo = styled.div`
  width: 120px;
`

const Nav = styled.nav`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: auto;
`

const NavPhone = styled.div`
  color: #4579b9;
  display: inline-block;
  font-size: 16px;
  text-align: center;

  a {
    align-items: center;
    border: 2px solid #4579b9;
    border-radius: 5px;
    color: #4579b9;
    display: flex;
    flex: 0 0 auto;
    padding: 4px 12px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    transition-property: background-color, border-color, color;

    &:hover {
      background-color: #4579b9;
      color: #fff;
    }

    svg {
      height: 18px;
      margin: 0 8px 0 0;
      width: 18px;
    }
  }
`

const HeaderComponent = ({ siteTitle, phoneNumber }) => (
  <HeaderWrapper>
    <Header>
      <StyledLogo>
        <Link to="/">
          <Logo alt={siteTitle} />
        </Link>
      </StyledLogo>
      <Nav>
        <NavPhone>
          <a href={`tel:${phoneNumber}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </svg>
            {phoneNumber}
          </a>
        </NavPhone>
      </Nav>
    </Header>
  </HeaderWrapper>
)

HeaderComponent.propTypes = {
  siteTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
}

HeaderComponent.defaultProps = {
  siteTitle: ``,
  phoneNumber: ``,
}

export default HeaderComponent
