import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const FooterWrapper = styled.div`
  background-color: #eff2fd;
  margin: 0;
  padding: 60px 30px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    padding: 60px 0;
  }
`

const Footer = styled.footer`
  align-items: center;
  color: #0f0f23;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
`

const SubFooterWrapper = styled.div`
  background-color: #dee4fb;
  margin: 0;
  padding: 20px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    padding: 20px 0;
  }
`

const SubFooter = styled.div`
  align-items: center;
  color: #0f0f23;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
  width: 100%;
`

const MainDetails = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: auto;

  h3 {
    margin-bottom: 12px;
  }

  ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 4px;
    }
  }
`

const Links = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: auto;
`

const LinkContent = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 0 40px;
  padding: 0;
  width: auto;

  a {
    color: #135bf9;
    font-size: 13px;
  }

  ul {
    font-size: 13px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 4px;
    }
  }
`

const FooterComponent = ({ siteTitle, phoneNumber }) => (
  <div>
    <FooterWrapper>
      <Footer>
        <MainDetails>
          <h3>{siteTitle}</h3>
          <ul>
            <li>PO Box 624</li>
            <li>Elk Grove Village, IL 60009</li>
            <li>{phoneNumber}</li>
          </ul>
        </MainDetails>
        <Links>
          <LinkContent>
            <h5>License</h5>
            <ul>
              <li>PCS-12312</li>
              <li>LATF-234 A</li>
            </ul>
          </LinkContent>
        </Links>
      </Footer>
    </FooterWrapper>
    <SubFooterWrapper>
      <SubFooter>&copy; {new Date().getFullYear()} Blue Sky MC, Inc.</SubFooter>
    </SubFooterWrapper>
  </div>
)

FooterComponent.propTypes = {
  siteTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
}

FooterComponent.defaultProps = {
  siteTitle: ``,
  phoneNumber: ``,
}

export default FooterComponent
